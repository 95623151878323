import "./checklist.css";

import React, { useCallback, useEffect } from "react";
import SVG from "react-inlinesvg";
import CheckSVG from "../../assets/icons/check.svg";
import CrossSVG from "../../assets/icons/x.svg";
import QuestionSVG from "../../assets/icons/question.svg";
import { useDiscordAuth } from "../helpers/discord";
import { useLoading } from "../helpers/loading";
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { PHANTOM_INSTALLATION_INSTRUCTIONS_URL } from "../constants";
import { useAppDispatch, useAppSelector } from "../hooks";
import { PhantomWalletName } from "@solana/wallet-adapter-wallets";
import {
  maybeHandleBlockchainOrBackendAPIError,
  useAvatarBlockchain,
} from "../helpers/blockchain";
import { setError } from "../reducers/errors";

require("@solana/wallet-adapter-react-ui/styles.css");

const { POSTSALE } = process.env;

const LOADING_TEXT = "Waiting for wallet to be linked..";
enum ChecklistState {
  UNKNOWN,
  INCOMPLETE,
  COMPLETE,
}

const ChecklistIconMap: Record<ChecklistState, string> = Object.freeze({
  [ChecklistState.UNKNOWN]: QuestionSVG,
  [ChecklistState.INCOMPLETE]: CrossSVG,
  [ChecklistState.COMPLETE]: CheckSVG,
});

const ChecklistItem: React.FC<{
  state?: ChecklistState;
  children?: React.ReactNode;
}> = (props) => {
  let svgIcon = null;
  if (props.state !== undefined) {
    const icon: string = ChecklistIconMap[props.state];
    svgIcon = <SVG src={icon} />;
  }

  return (
    <li
      checklist-state={ChecklistState[props.state ?? ChecklistState.COMPLETE]}
    >
      {svgIcon}
      <span>{props.children}</span>
    </li>
  );
};

export type ChecklistProps = {
  showDiscord?: boolean;
  showPhantom?: boolean;
  avatarBlockchain?: ReturnType<typeof useAvatarBlockchain>;
};

const DEFAULT_CHECKLIST_PROPS: Omit<
  Required<ChecklistProps>,
  "avatarBlockchain"
> & {
  avatarBlockchain?: ReturnType<typeof useAvatarBlockchain>;
} = {
  showDiscord: true,
  showPhantom: true,
};

export const Checklist: React.FC<ChecklistProps> = (props: ChecklistProps) => {
  const { showDiscord, showPhantom, avatarBlockchain } = {
    ...DEFAULT_CHECKLIST_PROPS,
    ...props,
  };
  return (
    <ul id="elixir-checklist">
      {showDiscord && <DiscordChecklistItem />}
      {showPhantom && <PhantomChecklistItem />}
      {avatarBlockchain && (
        <EnoughElixirChecklistItem avatarBlockchain={avatarBlockchain} />
      )}
    </ul>
  );
};

const DiscordChecklistItem: React.FC = () => {
  const auth = useDiscordAuth();
  return (
    <ChecklistItem
      state={
        auth.isAuthenticated
          ? ChecklistState.COMPLETE
          : ChecklistState.INCOMPLETE
      }
    >
      {auth.isAuthenticated ? (
        <>Discord account has been linked</>
      ) : (
        <>
          Link your <a href={auth.authenticateUrl}>Discord</a> account
        </>
      )}
    </ChecklistItem>
  );
};

const PhantomChecklistItem: React.FC = () => {
  const wallet = useWallet();
  const dispatch = useAppDispatch();

  const loadingScreen = useLoading();

  const currentLoadingText = useAppSelector((state) => state.loading.text);

  const isPhantomInstalled = "solana" in window;

  const onLinkWalletClick = useCallback<
    React.MouseEventHandler<HTMLAnchorElement>
  >(() => {
    try {
      loadingScreen.setText(LOADING_TEXT);
      wallet.select(PhantomWalletName);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // Effect once wallet.wallet is not null
  useEffect(() => {
    if (POSTSALE) {
      return;
    }
    (async () => {
      if (
        wallet.wallet !== null &&
        !wallet.connected &&
        (wallet.wallet?.readyState === "Installed" ||
          wallet.wallet?.readyState === "Loadable")
      ) {
        try {
          await wallet.connect();
        } catch (e) {
          const result = maybeHandleBlockchainOrBackendAPIError(
            (e as any)?.error
          );
          if (result?.error) {
            if (typeof result.error === "string") {
              dispatch(setError(result.error));
            }
          }
        } finally {
          if (currentLoadingText === LOADING_TEXT) {
            loadingScreen.resetText();
          }
        }
      }
    })();
  }, [
    wallet.connected,
    wallet.wallet !== null,
    currentLoadingText,
    wallet.wallet?.readyState,
  ]);

  if (isPhantomInstalled) {
    return (
      <ChecklistItem
        state={
          wallet.connected ? ChecklistState.COMPLETE : ChecklistState.INCOMPLETE
        }
      >
        {wallet.connected ? (
          <>Wallet has been linked</>
        ) : (
          <>
            Link your{" "}
            <a href="#" onClick={onLinkWalletClick}>
              Phantom
            </a>{" "}
            wallet
          </>
        )}
      </ChecklistItem>
    );
  } else {
    return (
      <ChecklistItem state={ChecklistState.INCOMPLETE}>
        Set up your <a href={PHANTOM_INSTALLATION_INSTRUCTIONS_URL}>Phantom</a>{" "}
        wallet
      </ChecklistItem>
    );
  }
};

const EnoughElixirChecklistItem: React.FC<{
  avatarBlockchain: ReturnType<typeof useAvatarBlockchain>;
}> = ({ avatarBlockchain }) => {
  const wallet = useWallet();
  const hasNonZeroElixir = avatarBlockchain.elixirBalanceInfo.hasNonZeroElixir;
  const neverHadElixir = avatarBlockchain.elixirBalanceInfo.neverHadElixir;
  if (!wallet.connected) {
    return null;
  }
  if (neverHadElixir) {
    return (
      <ChecklistItem state={ChecklistState.INCOMPLETE}>
        You have no Elixir
      </ChecklistItem>
    );
  }
  if (hasNonZeroElixir === undefined) {
    return (
      <ChecklistItem state={ChecklistState.UNKNOWN}>
        Checking for your Elixir
      </ChecklistItem>
    );
  }
  if (hasNonZeroElixir) {
    return (
      <ChecklistItem state={ChecklistState.COMPLETE}>
        You have an Elixir
      </ChecklistItem>
    );
  }
  return (
    <ChecklistItem state={ChecklistState.COMPLETE}>
      Elixir successfully redeemed
    </ChecklistItem>
  );
};
