import "./NavigationControls.css";
import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  randomizeBaseOptions,
  redo,
  resetToRandomPreset,
  undo,
} from "../reducers/avatarCreator";
import {
  selectBaseOptions,
  selectRawBaseColors,
} from "../reducers/avatarCreator/selectors";

export const NavigationControls: React.FC = () => {
  const dispatch = useAppDispatch();

  const baseOptions = useAppSelector(selectBaseOptions);
  const baseColors = useAppSelector(selectRawBaseColors);

  return (
    <div id="avatar-customizer-navigation-controls">
      {/* <button
        onClick={() => dispatch(randomizeBaseOptions())}
        title="Randomize"
      >
        <img
          src="/avatar/system/AC_Randomization Icon.png"
          alt="Randomize"
          aria-hidden
        />
      </button>

      <button
        onClick={() =>
          dispatch(
            randomizeBaseOptions({
              includeFacialHair: true,
            })
          )
        }
        title="Special control to use randomization with facial hair"
      >
        <img
          src="/avatar/system/AC_Randomization Icon.png"
          alt="Randomize With Facial Hair"
          style={{ backgroundColor: "red" }}
          aria-hidden
        />
      </button> */}

      <button
        onClick={() => dispatch(resetToRandomPreset(baseOptions, baseColors))}
        title="Restart"
      >
        <img
          src="/avatar/system/AC_Restart Icon.png"
          alt="Restart"
          aria-hidden
        />
      </button>

      <button onClick={() => dispatch(undo())} title="Undo">
        <img src="/avatar/system/AC_Undo Icon.png" alt="Undo" aria-hidden />
      </button>

      <button onClick={() => dispatch(redo())} title="Redo">
        <img src="/avatar/system/AC_Redo Icon.png" alt="Redo" aria-hidden />
      </button>
    </div>
  );
};
