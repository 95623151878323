import axios from "axios";
import localforage from "localforage";
import { useAsync } from "react-async";
import { getDiscordInfo, clearDiscordInfo, storeDiscordId } from "./storedInfo";
import { singletonHook } from "react-singleton-hook";
import { API_CONFIG } from "../../config";

const DISCORD_CLIENT_ID = "951300997723082802";

export const useDiscordAuthImpl = () => {
  const { data, isPending } = useAsync({ promiseFn: getDiscordMe });
  const id = data?.id;

  const { data: discordInfo, isPending: infoIsPending } = useAsync({
    promiseFn: getDiscordInfo,
    watch: id,
  });

  const { data: allowedToPurchaseData, isPending: allowedIsPending } = useAsync(
    {
      promiseFn: allowedToPurchase,
      id: id,
      watch: id,
    }
  );

  return {
    allowedToPurchase: allowedToPurchaseData,
    authenticateUrl: `https://discordapp.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${window.location.origin}/elixir/callback/&response_type=token&scope=identify`,
    discordInfo: discordInfo,
    isAuthenticated: isPending ? undefined : data?.id !== undefined,
    isPending: isPending || infoIsPending || allowedIsPending,
    me: data,
    id: data?.id,
    accessToken: discordInfo?.accessToken,
    logout,
  };
};

type DiscordAuth = ReturnType<typeof useDiscordAuthImpl>;

const initialState: DiscordAuth = {
  allowedToPurchase: undefined,
  authenticateUrl: `https://discordapp.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${window.location.origin}/elixir/callback/&response_type=token&scope=identify`,
  discordInfo: undefined,
  isAuthenticated: undefined,
  isPending: true,
  me: undefined,
  id: undefined,
  accessToken: undefined,
  logout: async () => {},
};

export const useDiscordAuth = singletonHook(initialState, useDiscordAuthImpl);

const getDiscordMe = async () => {
  const accessToken = await localforage.getItem("discordAccessToken");
  const response = await axios.get(`https://discordapp.com/api/users/@me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  await storeDiscordId(response.data.id);
  return response.data;
};

const logout = async () => {
  try {
    await clearDiscordInfo();

    window.location.reload();
  } catch (e) {
    console.error(e);
  }
};

const allowedToPurchase = async ({
  id,
}: {
  id: string | undefined;
}): Promise<boolean | undefined> => {
  if (!id) {
    return undefined;
  }

  const response = await axios.get(
    `${API_CONFIG.baseUrl}/allowlist/v1/check/${id}`,
    {
      headers: {
        Authorization: `Bearer ${await localforage.getItem(
          "discordAccessToken"
        )}`,
      },
    }
  );
  return response.data?.result;
};
