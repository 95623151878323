import { Route, Routes } from "react-router-dom";
import { AvatarCreator } from "./views/AvatarCreator";
import { AvatarWelcome } from "./views/AvatarWelcome";
import { CultureSelection } from "./views/CultureSelection";
import { Customizer } from "./views/Customizer";
import React from "react";
import store from "./store";
import { Provider } from "react-redux";
import { QueryStringSerializeHandler } from "./views/QueryStringSerializeHandler";
import { ElixirSolanaProvider } from "./utils/solana";
import { FinalizeAvatar } from "./views/FinalizeAvatar";
import { Footer } from "./components/footer";
import { Loading } from "./components/loading";

export const ACMain: React.FC = () => {
  return (
    <ElixirSolanaProvider>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<AvatarWelcome />} />

          <Route path="/culture" element={<AvatarCreator />}>
            <Route index element={<CultureSelection />} />
          </Route>

          <Route path="/creator" element={<AvatarCreator />}>
            <Route index element={<Customizer />} />
          </Route>
          <Route path="/finalize" element={<AvatarCreator />}>
            <Route index element={<FinalizeAvatar />} />
          </Route>
          <Route path="/view" element={<AvatarCreator />}>
            <Route index element={<FinalizeAvatar viewMode={true} />} />
          </Route>
        </Routes>
        <QueryStringSerializeHandler />
        <Footer />
        <Loading />
      </Provider>
    </ElixirSolanaProvider>
  );
};
