import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl, PublicKey } from "@solana/web3.js";

const SNET = process.env.SNET || "dev";
const APISERVER = process.env.APISERVER || "dev";

console.log(`SNET: ${SNET}`);
console.log(`APISERVER: ${APISERVER}`);

if (!["dev", "main"].includes(SNET)) {
  throw new Error(
    `SNET environment variable must be set to either "dev" or "main"`
  );
}

if (!["dev", "prod"].includes(APISERVER)) {
  throw new Error(
    `APISERVER environment variable must be set to either "dev" or "prod"`
  );
}

export const SOLANA_CONFIG = {
  network:
    SNET === "dev" ? WalletAdapterNetwork.Devnet : WalletAdapterNetwork.Mainnet,
  endpoint:
    SNET === "dev"
      ? clusterApiUrl(WalletAdapterNetwork.Devnet)
      : "https://withered-nameless-silence.solana-mainnet.quiknode.pro/30da137f15295b3b0e7d92db994efd7a6002fc3c/",
  mint:
    APISERVER === "dev"
      ? new PublicKey("TEx5E7gFvB5Cjb27TMCtp6M6h8vB3qr9bKA1VTktpCp")
      : new PublicKey("ELXRg7ft7c1uhgNcbsRQUKZw4LbPD5fzLeJmnZUJKQEB"),
};

export const API_CONFIG = {
  baseUrl:
    APISERVER === "dev"
      ? "https://549nyvjsbc.execute-api.us-east-1.amazonaws.com/stage"
      : "https://l454uhe9ej.execute-api.us-east-1.amazonaws.com/stage",
};
