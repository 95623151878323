import "./Header.css";

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import EverseedWEBP from "../../assets/everseed.webp";

require("@solana/wallet-adapter-react-ui/styles.css");

const { POSTSALE } = process.env;

export const Header: React.FC = () => {
  return (
    <nav>
      <a
        id="everseed-logo"
        title="Everseed Homepage"
        href="https://everseed.com"
      >
        <img src={EverseedWEBP} />
      </a>

      {POSTSALE ? null : <Link to="/">Home</Link>}

      <Link to="/culture">Create your Avatar</Link>
    </nav>
  );
};
