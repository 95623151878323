import "./ColorPicker.css";

import React from "react";
import { selectColorChoices } from "../reducers/avatarCreator/selectors";
import { useAppDispatch, useAppSelector } from "../hooks";
import { pickBaseColor } from "../reducers/avatarCreator";

export const ColorPicker: React.FC = () => {
  const dispatch = useAppDispatch();

  const colorChoicesState = useAppSelector(selectColorChoices);

  const colorChoices: React.ReactNode[] = colorChoicesState.map((color) => (
    <button
      key={color.color}
      onClick={() => dispatch(pickBaseColor(color))}
      style={{ backgroundColor: color.hex }}
    >
      {/* {color.color} */}
    </button>
  ));

  return (
    <div id="avatar-customizer-color-picker">
      {colorChoices.length > 1 ? colorChoices : null}
    </div>
  );
};
