import "./description.css";

import React, { useCallback } from "react";
import { Description } from "./description";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clear,
  selectErrorCode,
  selectErrorEnabled,
  selectErrorText,
  selectSpecialErrorReactNode,
  selectUnfilteredSourceError,
} from "../reducers/errors";

export const ErrorDescription: React.FC = (props) => {
  const errorText = useAppSelector(selectErrorText);
  const errorCode = useAppSelector(selectErrorCode);
  const unfilteredSourceError = useAppSelector(selectUnfilteredSourceError);
  const enabled = useAppSelector(selectErrorEnabled);
  const dispatch = useAppDispatch();

  const specialErrorReactNode = useAppSelector(selectSpecialErrorReactNode);

  const setEnabled = useCallback((newEnabled: boolean) => {
    if (!newEnabled) {
      dispatch(clear());
    }
  }, []);

  return (
    <Description state={[enabled, setEnabled]} buttonText={"Close"} avatarMode>
      <h1>Oh no!</h1>

      {specialErrorReactNode ? (
        specialErrorReactNode
      ) : (
        <>
          <p style={{ textAlign: "center" }}>{errorText}</p>
          {errorCode && (
            <p>
              Error code: <b>{errorCode}</b>
            </p>
          )}
          {unfilteredSourceError && (
            <p style={{ color: "#888" }}>{unfilteredSourceError}</p>
          )}
        </>
      )}
    </Description>
  );
};
