export enum CultureName {
  Boriu = "Boriu",
  Druhn = "Druhn",
  Nythiri = "Nythiri",
  Piyana = "Piyana",
  Twell = "Twell",
}

export const CULTURE_DESCRIPTIONS = {
  Boriu: "For many years, the great mountain ranges of the south were the domain of the Boriu culture. Stone masons, artisans and metalsmiths form the foundations of this society. Creativity and transformation are key ideals to the Boriu, who are known far and wide for the impeccably crafted artworks.",
  Druhn: "The Druhn come from a vast, central plains area. According to legend, the world’s earliest peoples first settled in these flood plains—an incredible legacy for the Druhn! Largely a stern and practical people, they have perfected their farming and ranching skills over the generations through a deep connection to the land.",
  Nythiri: "The Nythiri culture flourished in the deserts of the southeast despite the unforgiving climate. Through sheer ingenuity and perseverance, they became one of the most technologically advanced of all the world’s civilizations. Science and power form the cornerstone of the Nythiri’s outlook on the world.",
  Piyana: "Mere miles northwest of the mainland is the large island that the Piyana called home. Originally composed of small fishing communities, the Piyana eventually formed a majestic city in the bay to facilitate trade with mainlanders. As a whole, they have sought to find balance and harmony in all matters of life, inspired by the intricate interplay of the elements of nature that surround them.",
  Twell: "In the dense temperate forests of the west once lived the Twell. Expert gardeners, the Twell are intimately familiar with the great variety of botanical wonders that abound in the forest, and have created a unique means of breeding new plant species. The Twell tend to form loosely structured communities, guided by a free-spirited attitude and playful sense of mischief.",
}

export const ORDERED_CULTURES = [
  CultureName.Boriu,
  CultureName.Druhn,
  CultureName.Nythiri,
  CultureName.Piyana,
  CultureName.Twell,
];

export const PHANTOM_INSTALLATION_INSTRUCTIONS_URL = "https://everseed.substack.com/p/everseed-how-to-web3?s=r#:~:text=down%20each%20below!-,Wallet%20%F0%9F%91%9C,-A%20Web3%20wallet"
