export type BaseCategory =
  | "Culture"
  | "Body"
  | "SkinTone"
  | "Head"
  | "Ears"
  | "Nose"
  | "Mouth"
  | "Eyes"
  | "FrontHair"
  | "BackHair"
  | "Eyebrows"
  | "Eyewear"
  | "JewelryFacial"
  | "FacialHair";

export type AccessoryCategory = "Headwear" | "ClothesUpperBody" | "Background";

// NOTE: One should be *very* careful when making any changes to either
// BaseCategoryGroups or AllBaseCategories below. This is because our
// serializeToStringV0 and deserializeFromStringV0 functions save space by
// serializing a user's selected options to an array that assumes the ordering
// given by AllBaseCategories.
export const BaseCategoryGroups: Array<Array<BaseCategory>> = [
  ["Culture"],
  ["Body", "SkinTone"],
  ["Head", "Ears", "Nose", "Mouth", "Eyes"],
  ["FrontHair", "BackHair", "Eyebrows"],
  ["Eyewear", "JewelryFacial", "FacialHair"],
];

export const AllBaseCategories = BaseCategoryGroups.flat();

export const MatchColorCategories: Array<BaseCategory> = [
  "Head",
  "Body",
  "Nose",
  "Ears",
];

export const MatchHairColorCategories: Array<BaseCategory> = [
  "FrontHair",
  "BackHair",
  "FacialHair",
];

export const CanBeEmptyCategories: Array<BaseCategory> = ["FacialHair"];

export const ColorPickerCategories: Record<BaseCategory, boolean> = {
  // Categories that support the color picker.
  Mouth: true,
  Eyes: true,
  FrontHair: true,
  BackHair: true,
  Eyebrows: true,

  // Categories that don't support the color picker (because typescript requires
  // these keys to be exhaustive).
  Culture: false,
  Body: false,
  SkinTone: false,
  Head: false,
  Ears: false,
  Nose: false,
  Eyewear: false,
  JewelryFacial: false,
  FacialHair: false,
};
