import React, { useEffect } from "react";
import { useLocation, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import { selectSerializedRepresentation } from "../reducers/avatarCreator/selectors";
import { useAppDispatch, useAppSelector } from "../hooks";
import { loadFromString } from "../reducers/avatarCreator";

export const QueryStringSerializeHandler: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const serializedRepresentation = useAppSelector(
    selectSerializedRepresentation
  );

  useEffect(() => {
    if (serializedRepresentation) {
      navigate({
        search: location.pathname === "/" ? "" : `?avatar=${serializedRepresentation}`,
      }, {
        replace: true
      });
    }
  }, [serializedRepresentation, location.search, location.pathname]);

  return null;
};
