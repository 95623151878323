import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";
import { includes, isEqual } from "lodash";

import {
  BaseCategory,
  AccessoryCategory,
  ColorPickerCategories,
  AllBaseCategories,
  MatchColorCategories,
  MatchHairColorCategories,
} from "./types";

import * as utils from "../../utils/avatarCreator";
import {
  OptionColorDesignator,
  OptionDesignator,
  randomOptionColorForPart,
  randomPreset,
  toBaseColors,
  toBaseOptions,
} from "../../helpers/avatarAssets";

interface AvatarSnapshot {
  baseOptions: Record<BaseCategory, string | null>;
  baseColors: Record<BaseCategory, string | null>;
}

export interface State {
  hasVisitedCultureScreen: boolean;
  currentBaseCategory: BaseCategory;
  currentAccessoryCategory: AccessoryCategory;

  baseOptions: Record<BaseCategory, string | null>;
  baseColors: Record<BaseCategory, string | null>;
  accessoryOptions: Record<AccessoryCategory, string | null>;

  colorPickerActive: boolean;
  accessoryPickerActive: boolean;

  avatarHistory: Array<AvatarSnapshot>;
  historyIndex: number;
}

export const emptyAccessoryOptions: Record<AccessoryCategory, string | null> = {
  Headwear: null,
  ClothesUpperBody: null,
  Background: null,
};

export const initialState = (): State => {
  const startingPreset = randomPreset();
  const startingBaseOptions = toBaseOptions(startingPreset);
  const startingBaseColors = toBaseColors(startingPreset);

  const state: State = {
    hasVisitedCultureScreen: false,
    currentBaseCategory: "BackHair",
    currentAccessoryCategory: "Headwear",
    baseOptions: { ...startingBaseOptions },
    baseColors: { ...startingBaseColors },
    accessoryOptions: { ...emptyAccessoryOptions },
    colorPickerActive: true,
    accessoryPickerActive: false,
    avatarHistory: [
      {
        baseColors: { ...startingBaseOptions },
        baseOptions: { ...startingBaseColors },
      },
    ],
    historyIndex: 0,
  };

  const avatarParameter: string | null = new URLSearchParams(
    location.search
  ).get("avatar");

  if (avatarParameter !== null) {
    const { baseColors, baseOptions } =
      deserializeBaseAttributes(avatarParameter);

    Object.assign(state.avatarHistory[0], { baseColors, baseOptions });
    Object.assign(state, { baseColors, baseOptions });
  }

  return state;
};

interface PickAccessoryOptionPayload {
  category: AccessoryCategory;
  optionId: string;
}

export const recordAvatar = (state: Draft<State>): void => {
  if (state.historyIndex < state.avatarHistory.length - 1) {
    state.avatarHistory = state.avatarHistory.slice(0, state.historyIndex + 1);
  }

  const { baseOptions, baseColors } = state;

  const lastSavedAvatar = state.avatarHistory[state.avatarHistory.length - 1];
  const { baseOptions: lastBaseOptions, baseColors: lastBaseColors } =
    lastSavedAvatar;

  if (
    isEqual(baseOptions, lastBaseOptions) &&
    isEqual(baseColors, lastBaseColors)
  ) {
    return;
  }

  state.avatarHistory = [
    ...state.avatarHistory,
    {
      baseOptions: { ...baseOptions },
      baseColors: { ...baseColors },
    },
  ];
  state.historyIndex++;
};

export const resetAvatarState = (state: Draft<State>): void => {
  const { baseOptions, baseColors } = state;

  state.avatarHistory = [
    {
      baseOptions: { ...baseOptions },
      baseColors: { ...baseColors },
    },
  ];

  state.historyIndex = 0;
};

const deserializeBaseAttributes = (serializedAvatar: string) => {
  const baseOptionsAndColors = utils.deserializeFromStringV0(serializedAvatar);

  const baseOptions = Object.fromEntries(
    Object.entries(baseOptionsAndColors).map(([part, { option, color }]) => [
      part,
      option,
    ])
  ) as Record<BaseCategory, string | null>;

  const baseColors = Object.fromEntries(
    Object.entries(baseOptionsAndColors).map(([part, { option, color }]) => [
      part,
      color,
    ])
  ) as Record<BaseCategory, string | null>;

  return {
    baseOptions,
    baseColors,
  };
};

const slice = createSlice({
  name: "avatarCreator",
  initialState,
  reducers: {
    loadFromString(state: Draft<State>, action: PayloadAction<string>) {
      const { baseColors, baseOptions } = deserializeBaseAttributes(
        action.payload
      );

      state.baseOptions = baseOptions;
      state.baseColors = baseColors;

      resetAvatarState(state);
    },

    pickAccessoryCategory(
      state: Draft<State>,
      action: PayloadAction<AccessoryCategory>
    ) {
      state.currentAccessoryCategory = action.payload;
    },

    pickBaseCategory(state: Draft<State>, action: PayloadAction<BaseCategory>) {
      state.currentBaseCategory = action.payload;
    },

    pickAccessoryOption(
      state: Draft<State>,
      action: PayloadAction<PickAccessoryOptionPayload>
    ) {
      const { category, optionId } = action.payload;
      state.accessoryOptions[category] = optionId;
    },

    pickBaseOption(
      state: Draft<State>,
      action: PayloadAction<OptionDesignator>
    ) {
      const { part, option } = action.payload;
      state.baseOptions[part as BaseCategory] = option;

      recordAvatar(state);
    },

    pickBaseColor(
      state: Draft<State>,
      action: PayloadAction<OptionColorDesignator>
    ) {
      const { part, color } = action.payload;
      state.baseColors[part as BaseCategory] = color;

      if (includes(MatchColorCategories, part)) {
        MatchColorCategories.forEach((category) => {
          state.baseColors[category] = color;
        });
      }
      if (includes(MatchHairColorCategories, part)) {
        MatchHairColorCategories.forEach((category) => {
          state.baseColors[category] = color;
        });
      }

      recordAvatar(state);
    },

    pickAllBaseOptionsAndColors(
      state: Draft<State>,
      action: PayloadAction<
        Record<BaseCategory, { color: string | null; option: string | null }>
      >
    ) {
      const newBaseOptions = Object.fromEntries(
        Object.entries(action.payload).map(([part, { color, option }]) => [
          part as BaseCategory,
          option,
        ])
      ) as Record<BaseCategory, string | null>;

      const newBaseColors = Object.fromEntries(
        Object.entries(action.payload).map(([part, { color, option }]) => [
          part as BaseCategory,
          color,
        ])
      ) as Record<BaseCategory, string | null>;

      state.baseOptions = {
        ...newBaseOptions,
        Culture: state.baseOptions.Culture,
      };
      state.baseColors = newBaseColors;

      recordAvatar(state);
    },

    redo(state: Draft<State>) {
      if (state.historyIndex < state.avatarHistory.length - 1) {
        const { baseOptions, baseColors } =
          state.avatarHistory[++state.historyIndex];

        state.baseOptions = baseOptions;
        state.baseColors = baseColors;
      }
    },

    toggleAccessoryPicker(state: Draft<State>) {
      state.accessoryPickerActive = !state.accessoryPickerActive;
      state.accessoryOptions = {
        ...emptyAccessoryOptions,
      };
      state.currentAccessoryCategory = "Headwear";
    },

    toggleColorPicker(state: Draft<State>) {
      if (
        !state.accessoryPickerActive &&
        ColorPickerCategories[state.currentBaseCategory]
      ) {
        state.colorPickerActive = !state.colorPickerActive;
      }
    },

    undo(state: Draft<State>) {
      if (state.historyIndex > 0) {
        const { baseOptions, baseColors } =
          state.avatarHistory[--state.historyIndex];

        state.baseOptions = baseOptions;
        state.baseColors = baseColors;
      }
    },

    setHasVisitedCultureScreen(state: Draft<State>, action: PayloadAction<boolean>) {
      state.hasVisitedCultureScreen = action.payload;
    }
  },
});

export type RandomizeBaseOptionsOptions = {
  includeFacialHair: boolean;
};

const randomizeBaseOptionsDefaultOptions: RandomizeBaseOptionsOptions = {
  includeFacialHair: false,
};

export const randomizeBaseOptions = (
  opts: Partial<RandomizeBaseOptionsOptions> = {}
) => {
  const trueOps = { ...randomizeBaseOptionsDefaultOptions, ...opts };

  // @ts-ignore
  const options: Record<
    BaseCategory,
    OptionColorDesignator | { option: null; color: null }
  > = {};

  AllBaseCategories.forEach((untypedPart) => {
    const part = untypedPart as BaseCategory;

    if (part === "Eyewear") {
      options[part] = {
        option: null,
        color: null,
      };
      return;
    }

    if (part === "FacialHair") {
      if (!trueOps.includeFacialHair) {
        options[part] = {
          part,
          option: "0",
          color: "100",
        };
        return;
      }
    }

    const oc = randomOptionColorForPart({ part });

    if (oc) {
      options[part] = oc;
    } else {
      options[part] = {
        option: null,
        color: null,
      };
    }
  });

  // Follow the head option for all match color categories
  if (options["Head"].color) {
    const color = options["Head"].color;
    MatchColorCategories.forEach((category) => {
      if (options[category].color !== null) {
        options[category].color = color;
      }
    });
  }

  if (options["FrontHair"].color) {
    const color = options["FrontHair"].color;
    MatchHairColorCategories.forEach((category) => {
      if (options[category].color !== null) {
        options[category].color = color;
      }
    });
  }
  return pickAllBaseOptionsAndColors(options);
};

export const resetToRandomPreset = (
  baseOptions: Record<BaseCategory, string | null>,
  baseColors: Record<BaseCategory, string | null>
) => {
  // NOTE: We need to compare with baseOptions without Culture since Culture
  // isn't set in any of the presets.
  baseOptions = { ...baseOptions, Culture: null };

  let preset = randomPreset();

  // Ensure that rerolls can't land on the same preset since that may be
  // confusing to the user.
  while (
    isEqual(baseOptions, toBaseOptions(preset)) &&
    isEqual(baseColors, toBaseColors(preset))
  ) {
    preset = randomPreset();
  }

  return pickAllBaseOptionsAndColors(preset);
};

export const {
  loadFromString,
  pickAccessoryCategory,
  pickBaseCategory,
  pickBaseColor,
  pickAccessoryOption,
  pickBaseOption,
  pickAllBaseOptionsAndColors,
  redo,
  toggleAccessoryPicker,
  toggleColorPicker,
  undo,
  setHasVisitedCultureScreen,
} = slice.actions;

export default slice.reducer;
