import "./description.css";

import React, { useCallback } from "react";

import DescriptionElixirWEBP from "../../assets/elixir/Description Elixir.webp";
import DescriptionTitleWEBP from "../../assets/elixir/Title.webp";
import DescriptionAvatarLogoWEBP from "../../assets/avatar/AC_logo.webp";

type BooleanState = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export const Description: React.FC<{
  children?: React.ReactNode;
  buttonText?: string;
  state: BooleanState;
  permanent?: boolean;
  avatarMode?: boolean;
}> = (props) => {
  const [isEnabled, setIsEnabled] = props.state;

  const onClick: React.MouseEventHandler<HTMLElement> = useCallback(
    ({ target }) => {
      const isValidTarget =
        target instanceof HTMLElement &&
        /\bdescription-box\b/.test(target.className);

      if (isValidTarget) {
        setIsEnabled(false);
      }
    },
    []
  );

  return (
    <section
      className="description-box"
      is-enabled={isEnabled ? "true" : null}
      onClick={(e) => (props.permanent ? null : onClick(e))}
      is-permanent={props.permanent == true ? "true" : null}
      style={
        props.avatarMode
          ? {
              fontSize: 18,
            }
          : {}
      }
    >
      <div>
        {props.avatarMode ? null : (
          <img id="description-box-elixir" src={DescriptionElixirWEBP} />
        )}
        <img
          id="description-box-title"
          src={
            props.avatarMode ? DescriptionAvatarLogoWEBP : DescriptionTitleWEBP
          }
          style={
            props.avatarMode
              ? {
                  marginTop: "-3em",
                }
              : {}
          }
        />

        {props.children}

        {props.permanent == true ? null : (
          <button onClick={() => setIsEnabled(false)}>
            <span>{props.buttonText ?? "Okay"}</span>
          </button>
        )}
      </div>
    </section>
  );
};
