import "./footer.css"

import React from "react"

export const Footer: React.FC = () => {
    return (
        <footer>
            <a href="https://everseed.com">Everseed</a>
            <a href="https://everseed.com/privacy-policy.html">Privacy Policy</a>
        </footer>
    )
}
