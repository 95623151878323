// import "./ElixirSale.css";
import "./FinalizeAvatar.css";

import React, { useCallback, useEffect, useState } from "react";
import { Portrait } from "../components/Portrait";
import { useNavigate } from "react-router-dom";
import { Checklist } from "../components/checklist";
import SVG from "react-inlinesvg";
import { useWallet } from "@solana/wallet-adapter-react";

import PhantomSVG from "../../assets/icons/phantom.svg";
import { useAvatarBlockchain } from "../helpers/blockchain";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectAvatarSpec } from "../reducers/avatarCreator/selectors";
import CongratulationsWEBP from "../../assets/avatar/banner (1).webp";
import { Link } from "react-router-dom";
import LogoWEBP from "../../assets/avatar/AC_logo.webp";
import { CultureName } from "../constants";
import { useCultureRedirect } from "../helpers/cultureRedirect";
import { ErrorDescription } from "../components/ErrorDescription";
import {
  setError,
  setErrorFromBlockchainTransactionResult,
  setSpecialError,
} from "../reducers/errors";
import { SpecialError } from "../reducers/errors/ErrorMapping";

const COPY_LINK_TEXT = "Copy Link";

const { POSTSALE } = process.env;

export const FinalizeAvatar: React.FC<{
  viewMode?: boolean;
}> = (props) => {
  const viewMode = POSTSALE || props.viewMode;

  const cultureRedirect = useCultureRedirect();

  useEffect(() => {
    if (!viewMode) {
      cultureRedirect.navigate();
    }
  }, []);

  const wallet = useWallet();

  const [viewCopyButtonText, setViewCopyButtonText] = useState(COPY_LINK_TEXT);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (viewCopyButtonText != COPY_LINK_TEXT) {
      timeout = setTimeout(() => setViewCopyButtonText(COPY_LINK_TEXT), 750);
    }

    return () => clearTimeout(timeout);
  }, [viewCopyButtonText]);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(location.href).catch(console.error);

    setViewCopyButtonText("Copied to Clipboard!");
  }, []);

  return (
    <div id="finalize-container" view-mode={viewMode ? "true" : null}>
      {viewMode ? null : (
        <img id="finalize-container-logo" src={LogoWEBP} />
      )}

      <div id="finalize">
        <div>
          {viewMode ? <img id="view-avatar-logo" src={LogoWEBP} /> : null}

          <img
            id="finalize-congratulations"
            src={CongratulationsWEBP}
            hide-from-view="true"
          />
          <Portrait />

          {viewMode ? (
            <>
              <button className="shiny-button" onClick={copyLink}>
                <span>{viewCopyButtonText}</span>
              </button>
            </>
          ) : (
            <>
              <FinalizeNav />
            </>
          )}
        </div>

        <FinalizeRightPane />
      </div>

      <div id="elixir-logout-buttons" hide-from-view="true">
        <button
          id="elixir-unlink-wallet"
          disabled={!wallet.connected}
          onClick={() => wallet.disconnect()}
        >
          <SVG src={PhantomSVG} />
          <span>Unlink Phantom wallet</span>
        </button>
      </div>
      <ErrorDescription />
    </div>
  );
};

const FinalizeNav: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div id="finalize-nav" hide-from-view="true">
      <button
        id="finalize-nav-back"
        className="shiny-button"
        onClick={() => navigate("/creator")}
      >
        <span>Go Back</span>
      </button>

      <Link to="/view">
        <button className="shiny-button" tabIndex={-1}>
          <span>View Avatar</span>
        </button>
      </Link>
    </div>
  );
};

const FinalizeRightPane: React.FC = () => {
  const avatarBlockchain = useAvatarBlockchain();
  return (
    <div id="finalize-checklist-container" hide-from-view="true">
      <p>
        If you have an Elixir, you can consume it here to mint your own Ranger
        Avatar collectible with the finalized appearance shown to the left.
        Please link your Phantom wallet to begin the process.
      </p>
      <Checklist showDiscord={false} avatarBlockchain={avatarBlockchain} />
      <PurchaseButton avatarBlockchain={avatarBlockchain} />
    </div>
  );
};

const PurchaseButton: React.FC<{
  avatarBlockchain: ReturnType<typeof useAvatarBlockchain>;
}> = (props) => {
  const { exchangeElixirForAvatar, canExchangeElixirForAvatar } =
    props.avatarBlockchain;
  const avatarSpec = useAppSelector(selectAvatarSpec);
  const dispatch = useAppDispatch();
  return (
    <button
      id="finalize-mint-button"
      onClick={async () => {
        const result = await exchangeElixirForAvatar(avatarSpec);
        if (result.error) {
          if (typeof result.error === "string") {
            dispatch(setErrorFromBlockchainTransactionResult(result));
          } else {
            dispatch(setSpecialError(SpecialError.DEFAULT));
          }
        }
      }}
      disabled={!canExchangeElixirForAvatar}
      title="Purchase"
    />
  );
};
