import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setHasVisitedCultureScreen } from "../reducers/avatarCreator";

export const useCultureRedirect = () => {
    const dispatch = useAppDispatch();

    const {
        hasVisitedCultureScreen,
        baseOptions: { Culture }
    } = useAppSelector((state) => state.avatarCreator);

    const navigate = useNavigate();

    return {
        setHasVisitedCultureScreen(payload: boolean) {
            dispatch(setHasVisitedCultureScreen(payload));
        },

        navigate() {
            // Need two frames to catch up on the finalize screen
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    if (!hasVisitedCultureScreen || Culture === null) {
                        navigate("/culture")
                    }
                })
            })
        }
    };
};
