import "./AvatarWelcome.css"

import React from "react"
import { Link, useNavigate } from "react-router-dom"
// import AvatarPNG from "../../assets/avatar/portrait_placeholder.png"
import AvatarPlaceholderWEBP from "../../assets/avatar/Placeholder avatar.webp"
import EverseedWEBP from "../../assets/everseed.webp"
import LogoWEBP from "../../assets/avatar/AC_logo.webp"
import ElixirGIF from "../../assets/avatar/Unity_zG3zjaL5j6.gif"
import ElixirWEBM from "../../assets/avatar/elixir_tilting_scaled.webm"
import AvatarBeforeWEBP from "../../assets/avatar/website_1.webp"
import AvatarAfterWEBP from "../../assets/avatar/website_2.webp"

const { POSTSALE } = process.env;

export const AvatarWelcome: React.FC = () => {
    const videoRef = React.useRef<HTMLVideoElement>(null);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (POSTSALE) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    navigate("/culture");
                })
            })
        }
    }, []);

    React.useEffect(() => {
        if (videoRef.current != null) {
            videoRef.current.playbackRate = 2;
        }
    }, [videoRef, videoRef.current]);

    return POSTSALE ? null : (
        <section id="avatar-welcome">
            <img src={LogoWEBP} />
            
            <p id="avatar-welcome-intro">
                <div />

                <span>
                    As a player in Everseed, you will take on the role of a <b>ranger</b>. A ranger’s job is to go out into the dangerous parts of the world and cleanse the magically blighted groves that dot the landscape. In this time of struggle, there is a great need for those willing and able to brave the wilds for the benefit of the community. To become a ranger, all you have to do is raise your hand—today, you will have the opportunity to customize your Ranger Avatar in the world of Everseed. Welcome home!
                </span>
            </p>

            <Link to="/culture">
                <button className="shiny-button" tabIndex={-1}>
                    <span>
                        Create your Ranger Avatar
                    </span>
                </button>
            </Link>

            <div id="avatar-welcome-scroll">
                <h1>
                    <span>
                        Avatar Collectible Details
                    </span>
                </h1>

                <h2>How Avatars are Born</h2>

                <p className="one-column">
                    <span>
                        While anyone can try out Everseed Avatar Customization, a chosen few Everseed players will be 
                        able to “birth” (permanently mint) their own Ranger Avatar collectible. Let’s walk through that process.
                    </span>

                    {/* <img src={AvatarPNG} /> */}
                </p>

                <p className="two-column">
                    <div className="media">
                        <video src={ElixirWEBM} ref={videoRef} autoPlay muted loop />
                    </div>

                    <span>
                        First, you must have an Elixir—an everite-derived liquid that gives rangers their power. 
                        These were sold in our Elixir Sale to those on our allowlist and waitlist. With the Elixir in your possession, 
                        you will next need to customize your avatar on this page. The Avatar Creator will be unlocked from April 22nd 
                        4:20 PM UTC to April 24th 4:20 PM UTC.
                    </span>
                </p>

                <p className="two-column">
                    <span>
                        Once you have fully customized your avatar and are ready to finalize it, 
                        you will be asked to drink your Elixir (which will consume and burn it) to permanently lock in your Ranger Avatar. 
                        In exchange for your elixir, you will receive a placeholder avatar until the Avatar Birth process begins. 
                    </span>

                    <img src={AvatarPlaceholderWEBP} />
                </p>

                <p className="one-column">
                    <span>
                        All Ranger Avatars will be born starting on April 26th. Each person with a placeholder 
                        avatar will receive their fully customized avatar, plus a set of cosmetic items that were 
                        randomly selected and added to their avatar portrait. These cosmetic items have variable rarity, 
                        are exclusive to this mint, and will be equippable by your player avatar when the game is launched. 
                        All avatars with their cosmetics will be visually unique from each other. 
                    </span>

                    {/* <img src={AvatarPNG} /> */}
                </p>

                <div id="avatar-welcome-portraits">
                    <img src={AvatarBeforeWEBP} />
                    <img src={AvatarAfterWEBP} />
                </div>

                <h2 style={{ marginTop: "3em" }}>Avatar Collectible FAQ</h2>

                <p className="one-column">
                    <span>
                        <b>How many Ranger Avatar Collectibles will there be?</b>

                        <br/><br/>

                        There will be a total supply of 5,000 avatar collectibles in this genesis mint.
                    </span>
                </p>

                <p className="one-column">
                    <span>
                        <b>Can I trade my Elixir and/or Avatar collectible?</b>

                        <br/><br/>

                        The Elixir and the placeholder avatar collectible are frozen, which means they cannot 
                        be traded until they are thawed by us. When the avatars are born into their final form, 
                        they will be fully tradable. 
                    </span>
                </p>

                <p className="one-column">
                    <span>
                        <b>
                            What happens if I bought an Elixir, but I don’t burn it to finalize 
                            my avatar or I miss the avatar creation window?
                        </b>

                        <br/><br/>

                        You will receive an avatar with fully randomized attributes and your Elixir will 
                        remain frozen and untradeable forever. You will not be able to use this Elixir for 
                        any other purpose in the future—hanging onto your elixir will not benefit you in any way.
                    </span>
                </p>

                <p className="one-column">
                    <span>
                        <b>What types of tokens are the Elixir and avatar collectibles?</b>

                        <br/><br/>

                        The Elixir is a FUNGIBLE SPL token on the Solana blockchain that meets the Metaplex 
                        Fungible token standard. The avatar is a NON-FUNGIBLE SPL token on the Solana blockchain 
                        that meets the Metaplex Non-Fungible standard. As of this writing, the Metaplex Collections 
                        standard is not yet supported by any major community tooling on mainnet, so the avatars are 
                        not bundled together into an official collection. Nevertheless, Amihan views them as such 
                        and may at its discretion retroactively bundle these avatars into a verified Collection in 
                        the future for ease of use and verification.
                    </span>
                </p>

                <p className="one-column">
                    <span>
                        <b>How do I know if a blockchain transaction is legitimate?</b>

                        <br/><br/>

                        All blockchain transactions will go through the everseed.com domain. You can verify 
                        that an asset was issued by Amihan by making sure the <a href="https://solscan.io/account/FysLSdjPbfZ3X2Qu37486urbSPUYDQxSNMTYfuXRiaJQ">Amihan creator</a> is verified. 
                    </span>
                </p>

                <p className="one-column">
                    <span>
                        <b>What happens if I have issues during the process?</b>

                        <br/><br/>

                        If you have issues related to congestion on the Solana blockchain, please wait a short while 
                        and retry your transactions. 

                        <br/><br/>

                        <i>For all other issues, please ask questions in our <a href="https://discord.gg/everseed">Discord</a></i>.
                    </span>
                </p>
            </div>
        </section>
    )
}
