import React from "react";
import { UnexpectedErrorText } from "../../helpers/blockchainComponents";
import SilhouetteWEBP from "../../../assets/elixir/avatar silhoutte.webp";

export enum SpecialError {
  DEFAULT,
  SUBMISSION_SUCCESS,
}

export const ErrorMapping: Record<SpecialError, React.ReactNode> = {
  [SpecialError.DEFAULT]: (
    <p style={{ textAlign: "center" }}>{UnexpectedErrorText}</p>
  ),
  [SpecialError.SUBMISSION_SUCCESS]: (
    <div>
      <p style={{ textAlign: "center" }}>
        Congratulations! Your <b>Elixir</b> has been burned and you now have an
        Everseed Avatar. Your avatar is in the process of being born, and you
        will receive the final version on April 26th. Until that time, your
        avatar will have this placeholder appearance.
      </p>
      <img src={SilhouetteWEBP} alt="Avatar silhouette" />
    </div>
  ),
};
