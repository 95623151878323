import "./CultureSelection.css"

import React, { useCallback, useEffect } from "react";
import { CultureName, CULTURE_DESCRIPTIONS, ORDERED_CULTURES } from "../constants";
import { pickBaseOption } from "../reducers/avatarCreator";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Link } from "react-router-dom";
import LogoWEBP from "../../assets/avatar/AC_logo.webp"
import { useCultureRedirect } from "../helpers/cultureRedirect";

export const CultureSelection: React.FC = () => {
  const cultureRedirect = useCultureRedirect();

  const dispatch = useAppDispatch();

  const currentCulture : CultureName | null = useAppSelector(
   (state) => state.avatarCreator.baseOptions.Culture
  ) as CultureName | null;

  const selectCulture = useCallback((cultureName: CultureName) => {
    dispatch(
      pickBaseOption({
        part: "Culture",
        option: cultureName,
      })
    );
  }, []);

  useEffect(() => {
    cultureRedirect.setHasVisitedCultureScreen(currentCulture !== null);
  }, [currentCulture])

  return (
    <section id="culture-picker">
      <img id="culture-picker-logo" src={LogoWEBP} />

      <h1>Select your Culture</h1>

      <div id="culture-picker-cultures">
        {ORDERED_CULTURES.map((cultureName) => (
          <button
            className="culture"
            onClick={() => selectCulture(cultureName)}
            key={cultureName}
            is-selected={(currentCulture === null || currentCulture === cultureName) ? "true" : null}
          >
            <img
              src={`/avatar/culture/AC_Culture ${cultureName} Icon.png`}
              alt={`${cultureName} cultural icon`}
            />
      
            <h1>{cultureName}</h1>
          </button>
        ))}
      </div>

      {currentCulture != null ? <>
        <div className="culture-description">
          <h1>{currentCulture}</h1>
          <p>{CULTURE_DESCRIPTIONS[currentCulture]}</p>
        </div>

        <Link to="/creator">
          <button className="shiny-button" tabIndex={-1}>
            <span>
              Customize My Appearance
            </span>
          </button>
        </Link>
      </> : null}
    </section>
  );
};
