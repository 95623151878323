import { configureStore } from "@reduxjs/toolkit";

import avatarCreator from "./reducers/avatarCreator";
import errors from "./reducers/errors";
import loading from "./reducers/loading";

const store = configureStore({
  reducer: {
    avatarCreator,
    errors,
    loading,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
