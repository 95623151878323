const COLORS_HEX_MAP = {
  "1": "ffe0c3",
  "2": "f8dcad",
  "3": "fed6b2",
  "4": "fdcebe",
  "5": "f8c99f",
  "6": "f5bc8f",
  "7": "e3b792",
  "8": "d4a764",
  "9": "c59e73",
  "10": "ca8f65",
  "11": "bd8878",
  "12": "ca7f48",
  "13": "aa813f",
  "14": "a4774e",
  "15": "ad6648",
  "16": "8f6744",
  "17": "8d553a",
  "101": "e6e6e6",
  "102": "c8c8c8",
  "103": "6b6a68",
  "104": "2f3130",
  "105": "ad3733",
  "106": "ac5333",
  "107": "fff2c8",
  "108": "cfaf64",
  "109": "d6984f",
  "110": "904f27",
  "111": "604733",
  "112": "e4d70c",
  "113": "84b911",
  "114": "44d685",
  "115": "3fa7c4",
  "116": "7e4ebe",
  "117": "eb648c",
  "118": "ff7429",
  "119": "c9ab19",
  "120": "004936",
  "121": "00676c",
  "122": "0061a6",
  "123": "64007a",
  "124": "9e0055",
  "125": "851501",
  "126": "1e2a7c",
  "201": "5f9fbf",
  "202": "a15d39",
  "203": "f5d800",
  "204": "0c7b00",
  "205": "81cd15",
  "206": "9c999d",
  "207": "22c7b5",
  "208": "9ddeef",
  "209": "274a9b",
  "210": "038dd0",
  "211": "b632c4",
  "212": "e54774",
  "213": "f3551c",
  "214": "e00b0b",
  "215": "6e341d",
  "216": "efdafd",
  "301": "f26d6e",
  "302": "b02a29",
  "303": "774db3",
  "304": "166c81",
  "305": "272827",
  "306": "481f1d",
  "401": "c8c8c8",
  "402": "2f3130",
  "403": "fff2c8",
  "404": "efc755",
  "405": "604733",
  "406": "84b911",
  "407": "44d685",
  "408": "3fa7c4",
  "409": "7e4ebe",
  "410": "eb648c",
  "411": "ff7429",
  "412": "00676c",
  "413": "0061a6",
  "414": "64007a",
  "415": "851501",
  "416": "1e2a7c",
};

export const convertColorToHex = (color: string): string => {
  // @ts-ignore
  return `#${COLORS_HEX_MAP[color]}`;
};
