import React, { FC, useEffect, useMemo } from "react";
import {
  PhantomWalletAdapter,
  PhantomWalletName,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { SOLANA_CONFIG } from "../../config";

export const ElixirSolanaProvider: FC = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = SOLANA_CONFIG.network;

  // You can also provide a custom RPC endpoint.
  const endpoint = SOLANA_CONFIG.endpoint;

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(() => [new PhantomWalletAdapter()], [network]);
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>{children}</WalletProvider>
    </ConnectionProvider>
  );
};
