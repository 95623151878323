import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ErrorMapping, SpecialError } from "./ErrorMapping";
import { BlockchainTransactionResult } from "../../helpers/blockchain";

interface ErrorState {
  errorText: string;
  specialError: SpecialError | null;
  errorCode?: number;
  unfilteredSourceError?: string;
}

const initialState: ErrorState = {
  errorText: "",
  specialError: null,
};

const counterSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string>) {
      state.errorText = action.payload;
    },

    setErrorFromBlockchainTransactionResult(
      state,
      action: PayloadAction<BlockchainTransactionResult>
    ) {
      if (action.payload.error === undefined) {
        return;
      }
      state.errorCode = action.payload.errorCode;
      state.unfilteredSourceError = action.payload.unfilteredSourceError;
      if (action.payload.specialError) {
        state.specialError = action.payload.specialError;
        return;
      }
      if (typeof action.payload.error !== "string") {
        state.specialError = SpecialError.DEFAULT;
        return;
      }
      state.errorText = action.payload.error;
    },

    setSpecialError(state, action: PayloadAction<SpecialError>) {
      state.specialError = action.payload;
    },

    clear(state) {
      state.errorText = "";
      state.specialError = null;
    },
  },
});

export const {
  setError,
  setErrorFromBlockchainTransactionResult,
  setSpecialError,
  clear,
} = counterSlice.actions;
export default counterSlice.reducer;

export const selectErrorText = (state: RootState) => state.errors.errorText;
export const selectErrorCode = (state: RootState) => state.errors.errorCode;
export const selectUnfilteredSourceError = (state: RootState) =>
  state.errors.unfilteredSourceError;
export const selectErrorEnabled = (state: RootState) =>
  state.errors.errorText !== "" || state.errors.specialError !== null;

export const selectSpecialErrorReactNode = (state: RootState) => {
  const specialError = state.errors.specialError;
  if (specialError === null) {
    return null;
  }
  return ErrorMapping[specialError];
};
