import { useAppDispatch } from "../hooks";
import { setText } from "../reducers/loading";

export const useLoading = () => {
    const dispatch = useAppDispatch()

    return {
        setText(text: string) {
            dispatch(setText(text));
        },

        resetText() {
            dispatch(setText(""));
        },
    };
};
