import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  allColorsForOptionDesignator,
  allPortraitAssetsForOptionColorDesignator,
  AssetDesignator,
  avatarAssetPath,
  firstColorForOption,
  OptionColorDesignator,
  optionColorExists,
  OptionDesignator,
  selectorAssetDesignatorsForPart,
} from "../../helpers/avatarAssets";
import { find } from "lodash";
import { BaseCategory } from "./types";
import { serializeToStringV0 } from "../../utils/avatarCreator";
import { convertColorToHex } from "../../utils/colorsToHex";
import { CultureName } from "../../constants";

const selectCurrentBaseCategory = (state: RootState) =>
  state.avatarCreator.currentBaseCategory;
export const selectBaseOptions = (state: RootState) =>
  state.avatarCreator.baseOptions;
export const selectRawBaseColors = (state: RootState) =>
  state.avatarCreator.baseColors;

// Fills in base colors if there are color not chosen
const selectBaseColors = createSelector(
  selectBaseOptions,
  selectRawBaseColors,
  (baseOptions, baseColors): RootState["avatarCreator"]["baseColors"] => {
    const modifiedBaseColors = { ...baseColors };

    Object.keys(baseColors).forEach((untypedPart) => {
      const part = untypedPart as keyof typeof baseColors;
      const option = baseOptions[part];
      const color = baseColors[part];
      if (part === "Culture" || option === null || option === "0") {
        return;
      }
      if (color === null || !optionColorExists({ part, option, color })) {
        const firstColor = firstColorForOption({ part, option });
        if (firstColor === null) {
          console.warn(`No color found for ${part} ${option}`, baseColors);
          return;
        }
        modifiedBaseColors[part] = firstColor.color;
      }
    });

    return modifiedBaseColors;
  }
);

const selectSelectedOCDesignators = createSelector(
  selectBaseOptions,
  selectBaseColors,
  (
    baseOptions,
    baseColors
  ): Record<BaseCategory, OptionColorDesignator | null> => {
    // @ts-ignore
    const selectedOCDesignators: Record<
      BaseCategory,
      OptionColorDesignator | null
    > = {};
    Object.keys(baseOptions).forEach((untypedPart) => {
      const part = untypedPart as keyof typeof baseOptions;
      const option = baseOptions[part];
      const color = baseColors[part];
      if (option === null || color === null) {
        if (part === "Culture" && option !== null) {
          selectedOCDesignators[part] = { part, option, color: "99" };
        } else {
          selectedOCDesignators[part] = null;
        }
        return;
      }
      selectedOCDesignators[part] = { part, option, color };
    });
    return selectedOCDesignators;
  }
);

export const selectSerializedRepresentation = createSelector(
  selectSelectedOCDesignators,
  (selectedOCDesignators): string => {
    return serializeToStringV0(selectedOCDesignators);
  }
);

export const selectAvatarSpec = createSelector(
  selectSelectedOCDesignators,
  (selectedOCDesignators) => {
    return {
      Culture: serializedCulture(
        selectedOCDesignators.Culture?.option as CultureName
      ),
      SkinTone: parseInt(selectedOCDesignators.Body?.color || "0", 10),
      HairColor: parseInt(selectedOCDesignators.BackHair?.color || "0", 10),
      BackHair: {
        option: parseInt(selectedOCDesignators.BackHair?.option || "0", 10),
      },
      Body: {
        option: parseInt(selectedOCDesignators.Body?.option || "0", 10),
      },
      Ears: {
        option: parseInt(selectedOCDesignators.Ears?.option || "0", 10),
      },
      Eyebrows: {
        option: parseInt(selectedOCDesignators.Eyebrows?.option || "0", 10),
      },
      Eyes: {
        color: parseInt(selectedOCDesignators.Eyes?.color || "0", 10),
        option: parseInt(selectedOCDesignators.Eyes?.option || "0", 10),
      },
      FacialHair: {
        option: parseInt(selectedOCDesignators.FacialHair?.option || "0", 10),
      },
      FrontHair: {
        option: parseInt(selectedOCDesignators.FrontHair?.option || "0", 10),
      },
      // Eyewear: {
      //   option: parseInt(selectedOCDesignators.Eyewear?.option || "0", 10),
      // },
      Head: {
        option: parseInt(selectedOCDesignators.Head?.option || "0", 10),
      },
      Mouth: {
        option: parseInt(selectedOCDesignators.Mouth?.option || "0", 10),
        color: parseInt(selectedOCDesignators.Mouth?.color || "0", 10),
      },
      Nose: {
        option: parseInt(selectedOCDesignators.Nose?.option || "0", 10),
      },
    };
  }
);

const serializedCulture = (culture: CultureName): number => {
  switch (culture) {
    case CultureName.Boriu:
      return 1;
    case CultureName.Druhn:
      return 2;
    case CultureName.Nythiri:
      return 3;
    case CultureName.Piyana:
      return 4;
    case CultureName.Twell:
      return 5;
  }
  return 0;
};

export type AvatarSpecification = ReturnType<typeof selectAvatarSpec>;

const selectSelectableBaseOptionSelectorAssetDesignators = createSelector(
  selectCurrentBaseCategory,
  (category): AssetDesignator[] => {
    return selectorAssetDesignatorsForPart({ part: category });
  }
);

export const selectSelectableBaseOptionSelectorAssetDesignatorsAndPaths =
  createSelector(
    selectSelectableBaseOptionSelectorAssetDesignators,
    (assetDesignators): AssetDesignatorAndPath[] => {
      return assetDesignators.map((assetDesignator) => {
        return {
          designator: assetDesignator,
          path: avatarAssetPath(assetDesignator),
        };
      });
    }
  );

const selectSelectedBaseOptionString = createSelector(
  selectCurrentBaseCategory,
  selectBaseOptions,
  (category, options): string | null => {
    return options[category];
  }
);
export const selectSelectedBaseOption = createSelector(
  selectSelectedBaseOptionString,
  selectSelectableBaseOptionSelectorAssetDesignators,
  (optionString, selectableBaseOptions): OptionDesignator | null => {
    const designator = find(selectableBaseOptions, (option) => {
      return option.option === optionString;
    });
    if (designator !== undefined) {
      return designator;
    } else {
      return null;
    }
  }
);

export const selectColorChoices = createSelector(
  selectSelectedBaseOption,
  (option): (OptionColorDesignator & { hex: string })[] => {
    if (option === null) {
      return [];
    }
    const designators = allColorsForOptionDesignator(option);
    return designators.map((designator) => {
      return {
        ...designator,
        hex: convertColorToHex(designator.color),
      };
    });
  }
);

const selectCurrentPortraitAssetDesignators = createSelector(
  selectBaseOptions,
  selectBaseColors,
  (baseOptions, baseColors): AssetDesignator[] => {
    const designators: AssetDesignator[] = [];

    Object.entries(baseOptions).forEach(([part, option]) => {
      if (part === "Culture") {
        return;
      }
      if (option !== null) {
        const color = baseColors[part as BaseCategory];
        if (color !== null) {
          const ads = allPortraitAssetsForOptionColorDesignator({
            part,
            option,
            color,
          });
          designators.push(...ads);
        } else {
          console.warn(
            `An option was selected but color was null for ${part}, ${option}`
          );
        }
      }
    });

    return designators;
  }
);

export const selectCurrentPortraitAssetDesignatorsAndPaths = createSelector(
  selectCurrentPortraitAssetDesignators,
  (designators): AssetDesignatorAndPath[] => {
    const shirtDesignator: AssetDesignator = {
      part: "UC",
      option: "26",
      color: "99",
      portraitOrSelector: "p",
      layer: 140,
    };
    const chosenAssets = designators.map((designator) => ({
      designator,
      path: avatarAssetPath(designator),
    }));
    chosenAssets.push({
      designator: shirtDesignator,
      path: avatarAssetPath(shirtDesignator),
    });
    return chosenAssets;
  }
);

export type AssetDesignatorAndPath = {
  designator: AssetDesignator;
  path: string;
};
