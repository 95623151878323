import localforage from "localforage";

export type DiscordStoredInfo = {
  accessToken: string;
  expiresIn: string;
  tokenType: string;
  scope: string;
  id?: string | null;
};

export const storeDiscordInfo = async (
  info: DiscordStoredInfo
): Promise<void> => {
  await Promise.all([
    localforage.setItem("discordTokenType", info.tokenType),
    localforage.setItem("discordAccessToken", info.accessToken),
    localforage.setItem("discordExpiresIn", info.expiresIn),
    localforage.setItem("discordScope", info.scope),
  ]);
};

export const storeDiscordId = async (id: string): Promise<void> => {
  await localforage.setItem("discordId", id);
};

export const getDiscordInfo = async (): Promise<DiscordStoredInfo> => {
  const [tokenType, accessToken, expiresIn, scope, id] = await Promise.all([
    localforage.getItem("discordTokenType"),
    localforage.getItem("discordAccessToken"),
    localforage.getItem("discordExpiresIn"),
    localforage.getItem("discordScope"),
    localforage.getItem("discordId"),
  ]);

  return {
    accessToken,
    expiresIn,
    tokenType,
    scope,
    id,
  } as DiscordStoredInfo;
};

export const clearDiscordInfo = async () => {
  await Promise.all([
    localforage.removeItem("discordTokenType"),
    localforage.removeItem("discordAccessToken"),
    localforage.removeItem("discordExpiresIn"),
    localforage.removeItem("discordScope"),
    localforage.removeItem("discordId"),
  ]);
};
