// @ts-ignore
import ASSETS_RAW_JSON from "../../assetsPublicPaths.json";
import { flatten, includes, mapValues, sample, values } from "lodash";
import {
  AllBaseCategories,
  BaseCategory,
  CanBeEmptyCategories,
} from "../reducers/avatarCreator";
import { backhairOrdering } from "../utils/partOrdering";

export type PortraitOrSelector = "p" | "s";

export type PartDesignator = {
  part: string;
};

export type OptionDesignator = PartDesignator & {
  option: string;
};

export type OptionColorDesignator = OptionDesignator & {
  color: string;
};

export type NullableOptionColorDesignator = {
  part: string | null;
  option: string | null;
  color: string | null;
};

export type OptionColorPSDesignator = OptionColorDesignator & {
  portraitOrSelector: PortraitOrSelector;
};

export type AssetDesignator = OptionColorPSDesignator & {
  layer: number;
};

export const optionDesignatorsForPart = (
  partDesignator: PartDesignator
): OptionDesignator[] => {
  const optionDesignators: OptionDesignator[] = [];
  const { part } = partDesignator;
  // @ts-ignore
  const options = ASSETS_RAW_JSON[part];

  if (includes(CanBeEmptyCategories, part)) {
    optionDesignators.push({ part, option: "0" });
  }

  for (const option in options) {
    if (options.hasOwnProperty(option)) {
      const optionDesignator: OptionDesignator = {
        part,
        option,
      };
      optionDesignators.push(optionDesignator);
    }
  }

  return optionDesignators;
};

export const selectorAssetDesignatorsForPart = (
  partDesignator: PartDesignator
): AssetDesignator[] => {
  const designators: AssetDesignator[] = [];
  const { part } = partDesignator;
  // @ts-ignore
  const options = ASSETS_RAW_JSON[part];

  if (part === "FacialHair") {
    designators.push({
      part,
      option: "0",
      color: "99",
      portraitOrSelector: "s",
      layer: 30,
    });
  }

  const entries = Object.entries(options);

  if (part === "BackHair") {
    entries.sort((a, b) => {
      const aValue = backhairOrdering.findIndex((value) => value === a[0]);
      const bValue = backhairOrdering.findIndex((value) => value === b[0]);
      return aValue - bValue;
    });
  }

  entries.forEach(([option, untypedColors]) => {
    const colors = untypedColors as any;

    const hasAnyPortraits = Object.entries(colors).some(([color, pOrS]) => {
      return (pOrS as any).hasOwnProperty("p");
    });

    if (!hasAnyPortraits) {
      return;
    }

    for (const color in colors) {
      if (colors[color].hasOwnProperty("s")) {
        const layerNumber = parseInt(
          Object.keys(colors[color].s)[0] as string,
          10
        );
        designators.push({
          part,
          option,
          color,
          portraitOrSelector: "s",
          layer: layerNumber,
        });
        return;
      }
    }
  });
  return designators;
};

export const allColorsForOptionDesignator = (
  optionDesignator: OptionDesignator
): OptionColorDesignator[] => {
  const { part, option } = optionDesignator;

  // @ts-ignore
  const colors = ASSETS_RAW_JSON?.[part]?.[option];

  if (!colors) {
    return [];
  }

  const designators: OptionColorDesignator[] = [];

  for (const color in colors) {
    if (colors.hasOwnProperty(color) && colors[color].hasOwnProperty("p")) {
      const designator: OptionColorDesignator = {
        part,
        option,
        color,
      };
      designators.push(designator);
    }
  }

  return designators;
};

export const allPortraitAssetsForOptionColorDesignator = (
  optionDesignator: OptionColorDesignator
): AssetDesignator[] => {
  return allAssetsForOptionColorPSDesignator({
    ...optionDesignator,
    portraitOrSelector: "p",
  });
};

export const allAssetsForOptionColorPSDesignator = (
  optionColorPSDesignator: OptionColorPSDesignator
): AssetDesignator[] => {
  const { part, option, color, portraitOrSelector } = optionColorPSDesignator;

  const layers =
    // @ts-ignore
    ASSETS_RAW_JSON?.[part]?.[option]?.[color]?.[portraitOrSelector];

  if (!layers) {
    return [];
  }

  return Object.entries(layers).map(([layer, path]) => {
    const designator: AssetDesignator = {
      part,
      option,
      color,
      portraitOrSelector,
      layer: parseInt(layer, 10),
    };
    return designator;
  });
};

export const randomOptionColorForPart = (
  partDesignator: PartDesignator
): OptionColorDesignator | null => {
  const option = sample(optionDesignatorsForPart(partDesignator));
  if (option === undefined) {
    return null;
  }
  const color = sample(allColorsForOptionDesignator(option));
  if (color === undefined) {
    return null;
  }
  return color;
};

export const optionColorExists = (
  optionColorDesignator: OptionColorDesignator
): boolean => {
  const { part, option, color } = optionColorDesignator;

  // @ts-ignore
  const colorSpec = ASSETS_RAW_JSON?.[part]?.[option]?.[color];
  return colorSpec !== undefined;
};

export const firstColorForOption = (
  optionDesignator: OptionDesignator
): OptionColorDesignator | null => {
  const colors = allColorsForOptionDesignator(optionDesignator);
  if (colors.length === 0) {
    return null;
  }
  return colors[0];
};

const avatarAssetPathPng = (designator: AssetDesignator): string => {
  const { part, option, portraitOrSelector, color, layer } = designator;
  // @ts-ignore
  return ASSETS_RAW_JSON[part][option][color][portraitOrSelector][layer];
};

export const avatarAssetPath = (designator: AssetDesignator): string => {
  return avatarAssetPathPng(designator).replace(/\.png$/, ".webp");
};

export const toOptionColorDesignators = (
  baseOptions: Record<BaseCategory, string | null>,
  baseColors: Record<BaseCategory, string | null>
): Record<
  BaseCategory,
  OptionColorDesignator | { option: null; color: null }
> =>
  Object.fromEntries(
    AllBaseCategories.map((c: BaseCategory) => [
      c,
      {
        part: c,
        option: baseOptions[c],
        color: baseColors[c],
      },
    ])
  ) as Record<
    BaseCategory,
    OptionColorDesignator | { option: null; color: null }
  >;

export const toBaseOptions = (
  designators: Record<BaseCategory, NullableOptionColorDesignator>
): Record<BaseCategory, string | null> =>
  mapValues(designators, (d) => d.option);

export const toBaseColors = (
  designators: Record<BaseCategory, NullableOptionColorDesignator>
): Record<BaseCategory, string | null> =>
  mapValues(designators, (d) => d.color);

// TODO(vdonato): Replace with the presets that we actually want.
export const presets: Array<
  Record<BaseCategory, NullableOptionColorDesignator>
> = [
  {
    Body: { part: "Body", option: "1", color: "17" },
    Head: { part: "Head", option: "5", color: "17" },
    Ears: { part: "Ears", option: "1", color: "17" },
    Nose: { part: "Nose", option: "4", color: "17" },

    Mouth: { part: "Mouth", option: "1", color: "303" },
    Eyes: { part: "Eyes", option: "19", color: "215" },
    FrontHair: { part: "FrontHair", option: "24", color: "116" },
    BackHair: { part: "BackHair", option: "55", color: "116" },
    Eyebrows: { part: "Eyebrows", option: "2", color: "100" },
    FacialHair: { part: "FacialHair", option: "0", color: "110" },

    // Specially-handled or unused
    Culture: { part: null, option: null, color: null },
    SkinTone: { part: null, option: null, color: null },
    Eyewear: { part: null, option: null, color: null },
    JewelryFacial: { part: null, option: null, color: null },
  },
  {
    Body: { part: "Body", option: "1", color: "1" },
    Head: { part: "Head", option: "3", color: "1" },
    Ears: { part: "Ears", option: "4", color: "1" },
    Nose: { part: "Nose", option: "10", color: "1" },

    Mouth: { part: "Mouth", option: "1", color: "304" },
    Eyes: { part: "Eyes", option: "33", color: "203" },
    FrontHair: { part: "FrontHair", option: "16", color: "120" },
    BackHair: { part: "BackHair", option: "31", color: "120" },
    Eyebrows: { part: "Eyebrows", option: "1", color: "100" },
    FacialHair: { part: "FacialHair", option: "0", color: "110" },

    // Specially-handled or unused
    Culture: { part: null, option: null, color: null },
    SkinTone: { part: null, option: null, color: null },
    Eyewear: { part: null, option: null, color: null },
    JewelryFacial: { part: null, option: null, color: null },
  },
  {
    Body: { part: "Body", option: "1", color: "14" },
    Head: { part: "Head", option: "2", color: "14" },
    Ears: { part: "Ears", option: "5", color: "14" },
    Nose: { part: "Nose", option: "11", color: "14" },

    Mouth: { part: "Mouth", option: "1", color: "100" },
    Eyes: { part: "Eyes", option: "16", color: "204" },
    FrontHair: { part: "FrontHair", option: "27", color: "125" },
    BackHair: { part: "BackHair", option: "17", color: "125" },
    Eyebrows: { part: "Eyebrows", option: "18", color: "100" },
    FacialHair: { part: "FacialHair", option: "21", color: "125" },

    // Specially-handled or unused
    Culture: { part: null, option: null, color: null },
    SkinTone: { part: null, option: null, color: null },
    Eyewear: { part: null, option: null, color: null },
    JewelryFacial: { part: null, option: null, color: null },
  },
  {
    Body: { part: "Body", option: "1", color: "8" },
    Head: { part: "Head", option: "1", color: "8" },
    Ears: { part: "Ears", option: "6", color: "8" },
    Nose: { part: "Nose", option: "3", color: "8" },

    Mouth: { part: "Mouth", option: "1", color: "100" },
    Eyes: { part: "Eyes", option: "26", color: "213" },
    FrontHair: { part: "FrontHair", option: "2", color: "115" },
    BackHair: { part: "BackHair", option: "11", color: "115" },
    Eyebrows: { part: "Eyebrows", option: "10", color: "100" },
    FacialHair: { part: "FacialHair", option: "5", color: "115" },

    // Specially-handled or unused
    Culture: { part: null, option: null, color: null },
    SkinTone: { part: null, option: null, color: null },
    Eyewear: { part: null, option: null, color: null },
    JewelryFacial: { part: null, option: null, color: null },
  },
];

export const randomPreset = (): Record<
  BaseCategory,
  NullableOptionColorDesignator
> => sample(presets)!;

// Try fetching assets for each of the 4 preset avatars to warm the cache.
// We do this on a best-effort basis and drop any errors that we may run into.
export const fetchPresetAssets = (): void => {
  const designators = flatten(presets.map(values));
  designators.forEach((d) => {
    if (d.part === null) {
      return;
    }

    allPortraitAssetsForOptionColorDesignator(
      d as OptionColorDesignator
    ).forEach((assetDesignator) => {
      const path = avatarAssetPath(assetDesignator);
      fetch(path).catch(() => {});
    });
  });
};
