import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useDiscordAuth } from "./discord";
import React, { useCallback, useState } from "react";
import axios from "axios";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { AvatarSpecification } from "../reducers/avatarCreator/selectors";
import { useLoading } from "./loading";

import { getAssociatedTokenAddress } from "@solana/spl-token";
import { useAsync } from "react-async";

import { UnexpectedErrorText } from "./blockchainComponents";
import { API_CONFIG, SOLANA_CONFIG } from "../../config";
import { getDiscordInfo } from "./storedInfo";
import { SpecialError } from "../reducers/errors/ErrorMapping";

const PURCHASE_LAG_TIME_S = 120;

export const useBlockchain = () => {
  const { connection } = useConnection();
  const { publicKey, connected, sendTransaction } = useWallet();
  const loadingScreen = useLoading();

  const { id, accessToken, allowedToPurchase } = useDiscordAuth();

  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);

  const canStartTransaction =
    !transactionLoading &&
    accessToken !== undefined &&
    connected &&
    allowedToPurchase;

  const purchaseElixir =
    useCallback(async (): Promise<BlockchainTransactionResult> => {
      const discordIdPromise = (async () => {
        if (id !== undefined) {
          return id;
        }
        console.warn("Falling back to stored Discord ID info");
        const { id: storedId } = await getDiscordInfo();
        return storedId;
      })();
      if (!canStartTransaction) {
        console.warn("You cannot purchase Elixir right now.");
      }

      let cancelUpdateTextOverTime;

      const updateTextOverTime = async () => {
        let initiatedTime = Date.now();
        let exitEarly = false;

        cancelUpdateTextOverTime = () => {
          exitEarly = true;
        };

        let elapsedTime = Date.now() - initiatedTime;
        setTransactionLoading(true);
        while (!exitEarly && elapsedTime <= PURCHASE_LAG_TIME_S * 1000) {
          loadingScreen.setText(
            `Purchasing Elixir... please confirm within ${Math.ceil(
              PURCHASE_LAG_TIME_S - elapsedTime / 1000
            )} seconds.`
          );
          await new Promise((resolve) => setTimeout(resolve, 200));
          elapsedTime = Date.now() - initiatedTime;
        }
        loadingScreen.resetText();
      };

      const updaterPromise = updateTextOverTime();
      try {
        const discordId = await discordIdPromise;
        const response = await axios.post(
          `${API_CONFIG.baseUrl}/elixir/v1/start-transaction/${discordId}`,
          { walletAddress: publicKey },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const bcResult = await signTransactionAndSendToBlockchain(
          Transaction.from(response.data.signedTransaction.data),
          connection
        );

        if (bcResult) {
          const error = bcResult.value.err;
          // TODO (Allen): Use this error reasonably
          if (error) {
            console.error("Blockchain error:", error);
          }
        }
      } catch (err) {
        const res = maybeHandleBlockchainOrBackendAPIError(err);
        if (res) {
          return res;
        }

        return {
          error: UnexpectedErrorText,
          success: false,
        };
      } finally {
        (cancelUpdateTextOverTime as unknown as () => void)?.();
        loadingScreen.resetText();
        setTransactionLoading(false);
      }
      return {
        success: true,
      };
    }, [sendTransaction]);

  return {
    canStartTransaction,
    loading: transactionLoading,
    purchaseElixir,
  };
};

export type AvatarBlockchainReturnType = {
  loading: boolean;
  exchangeElixirForAvatar: (
    avatarSpec: AvatarSpecification
  ) => Promise<BlockchainTransactionResult>;
  canExchangeElixirForAvatar: boolean;
  elixirBalanceInfo: ReturnType<typeof useElixirBalanceInfo>;
};

export const useAvatarBlockchain = (): AvatarBlockchainReturnType => {
  const { connection } = useConnection();
  const { publicKey, connected, sendTransaction } = useWallet();
  const loadingScreen = useLoading();

  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);

  const elixirBalanceInfo = useElixirBalanceInfo({
    watch: transactionLoading,
  });

  const exchangeElixirForAvatar = useCallback(
    async (
      avatarSpec: AvatarSpecification
    ): Promise<BlockchainTransactionResult> => {
      setTransactionLoading(true);

      let cancelUpdateTextOverTime;
      let extraInfoText = "";

      const updateTextOverTime = async () => {
        let initiatedTime = Date.now();
        let exitEarly = false;

        cancelUpdateTextOverTime = () => {
          exitEarly = true;
        };

        let elapsedTime = Date.now() - initiatedTime;
        while (!exitEarly && elapsedTime <= PURCHASE_LAG_TIME_S * 1000) {
          loadingScreen.setText(
            extraInfoText ||
              `Finalizing your Avatar... please confirm within ${Math.ceil(
                PURCHASE_LAG_TIME_S - elapsedTime / 1000
              )} seconds.`
          );
          await new Promise((resolve) => setTimeout(resolve, 200));
          elapsedTime = Date.now() - initiatedTime;
        }
        loadingScreen.resetText();
      };

      const updaterPromise = updateTextOverTime();
      try {
        const response = await axios.post(
          `${API_CONFIG.baseUrl}/avatars/v1/start-transaction`,
          { walletAddress: publicKey, avatarOptions: avatarSpec }
        );

        const signature = await signAndSendTranscation(
          Transaction.from(response.data.signedTransaction.data),
          connection
        );

        extraInfoText =
          "Avatar Mint in progress. Please wait while we confirm the result.";

        const bcResult = await connection.confirmTransaction(
          signature,
          "confirmed"
        );

        if (bcResult) {
          const error = bcResult.value.err;
          // TODO (Allen): Use this error reasonably
          if (error) {
            console.error("Blockchain error:", error);
          }
        }
      } catch (e) {
        const res = maybeHandleBlockchainOrBackendAPIError(e);
        if (res) {
          return res;
        }

        return {
          error: UnexpectedErrorText,
          success: false,
        };
      } finally {
        (cancelUpdateTextOverTime as unknown as () => void)?.();
        loadingScreen.resetText();
        setTransactionLoading(false);
      }
      return {
        success: true,
        specialError: SpecialError.SUBMISSION_SUCCESS,
      };
    },
    [sendTransaction]
  );

  const canExchangeElixirForAvatar = Boolean(
    connected && !transactionLoading && elixirBalanceInfo.hasNonZeroElixir
  );

  return {
    loading: transactionLoading,
    exchangeElixirForAvatar,
    canExchangeElixirForAvatar,
    elixirBalanceInfo,
  };
};

async function signAndSendTranscation(
  transaction: Transaction,
  connection: Connection
) {
  const phantom = (window as any).solana;

  console.log("Preparing to sign transaction...");
  const signed = await phantom.signTransaction(transaction);
  console.log("Signed the transaction, about to send to blockchain...");
  const signature = await connection.sendRawTransaction(signed.serialize());
  console.log("Transaction sent to blockchain!");
  return signature;
}

async function signTransactionAndSendToBlockchain(
  transaction: Transaction,
  connection: Connection
) {
  const signature = await signAndSendTranscation(transaction, connection);

  if (signature) {
    return await connection.confirmTransaction(signature, "confirmed");
  }
}

export const useElixirBalanceInfo = ({ watch }: { watch?: any }) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { data, isPending } = useAsync({
    promiseFn: fetchElixirBalanceInfo,
    watchFn: (props, prevProps) => {
      if (props.publicKey !== prevProps.publicKey) {
        return true;
      }
      if (props.customWatchParam !== prevProps.customWatchParam) {
        return true;
      }
      return false;
    },
    connection,
    publicKey,
    customWatchParam: watch,
  });

  const neverHadElixir = data === null;

  const amountOrUndefined = data?.value?.amount;

  return {
    data,
    isPending,
    hasNonZeroElixir:
      amountOrUndefined === undefined ? undefined : amountOrUndefined !== "0",
    neverHadElixir,
  };
};

const fetchElixirBalanceInfo = async ({
  publicKey,
  connection,
}: {
  publicKey: PublicKey | null;
  connection: Connection;
}) => {
  try {
    if (publicKey === null) {
      return null;
    }

    // const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
    //   programId: TOKEN_PROGRAM_ID,
    //   mint: ELIXIR_MINT_PUBKEY,
    // });

    const playerElixirATA = await getAssociatedTokenAddress(
      SOLANA_CONFIG.mint,
      publicKey
    );

    try {
      const balance = await connection.getTokenAccountBalance(playerElixirATA);
      console.log("Found Elixir balance: ", balance);
      return balance;
    } catch (e) {
      return null;
    }
  } catch (e) {
    console.error("Error fetching elixir balance info", e);
    return null;
  }
};

const PRICE_PER_ELIXIR = 1002500000; // Includes amount of buffer

export const useSolanaBalanceInfo = () => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { data, isPending } = useAsync({
    promiseFn: fetchSolanaBalanceInfo,
    watchFn: (props, prevProps) => {
      if (props.publicKey !== prevProps.publicKey) {
        return true;
      }
      return false;
    },
    connection,
    publicKey,
  });

  const balance = data != null ? data : undefined;

  return {
    balance,
    doesNotHaveEnoughSolToBuyElixir:
      balance !== undefined ? balance <= PRICE_PER_ELIXIR : undefined, // LEQ because exact equality won't cover the fee
  };
};
const fetchSolanaBalanceInfo = async ({
  publicKey,
  connection,
}: {
  publicKey: PublicKey | null;
  connection: Connection;
}): Promise<number | null> => {
  try {
    if (publicKey === null) {
      return null;
    }

    const balance = await connection.getBalance(publicKey);
    console.log("Found solana balance: ", balance);
    return balance;
  } catch (e) {
    console.error("Error fetching solana balance info", e);
    return null;
  }
};

export type BlockchainTransactionResult = {
  success: boolean;
  error?: React.ReactNode;
  errorCode?: number;
  unfilteredSourceError?: string;
  specialError?: SpecialError;
};

export const maybeHandleBlockchainOrBackendAPIError = (
  error: any
): BlockchainTransactionResult | undefined => {
  console.warn("Blockchain or Backend API error: ", error);
  console.warn(error);
  console.warn("logs");
  console.warn(error?.logs);
  console.warn("message");
  console.warn(error?.message);
  console.warn("stack");
  console.warn(error?.stack);

  if (axios.isAxiosError(error)) {
    const reason = error.response?.data?.reason || "";
    const status = error.response?.status;
    switch (status) {
      case 500:
        if (reason.match(/uncaught error/)) {
          return {
            success: false,
            errorCode: status,
            error:
              "We had an unexpected error. Please send this error message to us on Discord.",
            unfilteredSourceError: reason,
          };
        }
        if (reason.match(/BE failure/)) {
          return {
            success: false,
            errorCode: status,
            error:
              "We had a backend error communicating with Solana. Please send this error message to us on Discord.",
            unfilteredSourceError: reason,
          };
        }
        break;
      case 503:
        return {
          success: false,
          errorCode: status,
          error:
            "Our servers are currently experiencing high load. Please try again in a minute or so. Check our Discord for updates.",
        };
      case 504:
        return {
          success: false,
          errorCode: status,
          error:
            "Our servers are currently experiencing high load. Please try again in a minute or so. Check our Discord for updates.",
        };
      case 400:
        return {
          success: false,
          errorCode: status,
          error:
            "Our backend received a bad request. Please refresh the page and try again.",
        };
      case 403:
        return {
          success: false,
          errorCode: status,
          error:
            "We don't have a database record for your wallet. Please ask us for assistance in #mint-support in Discord.",
        };
      case 404:
        return {
          success: false,
          errorCode: status,
          error:
            "We haven't turned on Avatar creation yet! Please check back in a bit. Check our Discord for updates.",
        };
      case 409:
        return {
          success: false,
          errorCode: status,
          error:
            "You might have clicked the mint button too many times too quickly. If you got a Phantom pop-up and accepted it, the transaction may have gone through. Refresh the page and try again.",
        };
    }
  }

  if (error?.logs) {
    const logs = error.logs;
    for (const log of logs) {
      if ((log as string).match(/^Transfer: insufficient lamports/)) {
        return {
          success: false,
          error:
            "You don't have enough SOL to purchase an Elixir! Perhaps you don't have enough to pay the transaction fee. The total cost including the transaction fee is about 1.0025 SOL.",
        };
      }
    }
  }

  if (error?.code) {
    switch (error.code) {
      case 4900:
        return {
          error:
            "Your Phantom wallet could not connect to the Solana network. Please check your wallet and your network connection and try again when you’re ready.",
          success: false,
        };
      case 4100:
        return {
          error:
            "Your Phantom wallet told us that you didn’t authorize our request. Please check your wallet and try again when you’re ready.",
          success: false,
        };
      case 4001:
        return {
          error:
            "Your Phantom wallet told us that you rejected our transaction request. Please check your wallet and try again when you’re ready.",
          success: false,
        };
      case -3200:
      case -32003:
      case -32601:
        return {
          error:
            "Something went wrong with your Phantom wallet. Please check your wallet and your network connection and try again when you’re ready.",
          success: false,
        };
    }
  }

  if (error?.response?.data?.reason === "Different wallet address") {
    return {
      error:
        "You've already tried to purchase Elixir with a different wallet address. Switch to that wallet and try again.",
      success: false,
    };
  }
  return undefined;
};
