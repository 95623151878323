// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/avatar/AC_Page bg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar-creator-content {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center bottom;\n  background-size: cover;\n}\n\n:root {\n  font-size: 1.2vmin;\n}\n\n@media (max-width: 80em) {\n  :root {\n    font-size: calc(0.8vw);\n  }\n}", "",{"version":3,"sources":["webpack://./src/elixir/views/AvatarCreator.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;;EAEjB,2EAA8E;EAC9E,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".avatar-creator-content {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n\n  background: url(\"../../assets/avatar/AC_Page bg.webp\") no-repeat center bottom;\n  background-size: cover;\n}\n\n:root {\n  font-size: 1.2vmin;\n}\n\n@media (max-width: 80em) {\n  :root {\n    font-size: calc(0.8vw);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
