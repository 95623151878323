import "./Portrait.css";

import React from "react";
import { selectCurrentPortraitAssetDesignatorsAndPaths } from "../reducers/avatarCreator/selectors";
import { useAppSelector } from "../hooks";

export const Portrait: React.FC = () => {
  const designatorsAndPaths = useAppSelector(
    selectCurrentPortraitAssetDesignatorsAndPaths
  );

  return (
    <div className="portrait-container" id="avatar-customizer-portrait">
      {designatorsAndPaths.map(({ path, designator }, index) => (
        <img
          key={index}
          className="portrait-image"
          src={path}
          alt="portrait"
          height={500}
          width={500}
          style={{ zIndex: 1000 - designator.layer }}
        />
      ))}
    </div>
  );
};
