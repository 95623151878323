import "./OptionPicker.css";

import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  AssetDesignatorAndPath,
  selectSelectableBaseOptionSelectorAssetDesignatorsAndPaths,
} from "../reducers/avatarCreator/selectors";
import { AssetDesignator } from "../helpers/avatarAssets";
import { Button } from "antd";
import { pickBaseOption } from "../reducers/avatarCreator";

export const OptionPicker: React.FC = () => {
  const designatorsAndPaths = useAppSelector(
    selectSelectableBaseOptionSelectorAssetDesignatorsAndPaths
  );

  return (
    <div id="avatar-customizer-option-picker">
      {designatorsAndPaths.map(({ designator, path }) => (
        <PartOption key={designator.option} {...{ designator, path }} />
      ))}
    </div>
  );
};

const PartOption: React.FC<AssetDesignatorAndPath> = ({ designator, path }) => {
  const dispatch = useAppDispatch();
  return (
    <button
      onClick={() =>
        dispatch(
          pickBaseOption({ part: designator.part, option: designator.option })
        )
      }
    >
      <img src={path} alt={`Option ${designator.option}`} />
    </button>
  );
};
