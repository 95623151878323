import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LoadingState {
  text: string
}

const initialState: LoadingState = {
  text: ""
}

const counterSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setText(state, action: PayloadAction<string>) {
      state.text = action.payload;

      return state;
    },

    resetText(state, action: PayloadAction<string>) {
      state.text = action.payload;

      return state;
    }
  },
})

export const { setText, resetText } = counterSlice.actions
export default counterSlice.reducer