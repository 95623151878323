import React from "react";
import { useAppSelector } from "../hooks"
import "./loading.css"
import CorgiWEBM from "../../assets/corgi_transparent.webm"
import CorgiWEBP from "../../assets/corgi_transparent.webp"

export const Loading: React.FC = () => {
    const loadingText: string = useAppSelector(({ loading }) => loading.text);

    const isLoading = loadingText != "";

    return (
        <section id="loading-screen" style={{
            opacity: isLoading ? "1" : "0", 
            pointerEvents: isLoading ? 'all' : 'none', 
        }}>
            <video
                src={CorgiWEBM}
                poster={CorgiWEBP}
                title="Corgi load animation"
                autoPlay
                muted
                loop
            />

            <h1>{loadingText}</h1>
        </section>
    );
};
