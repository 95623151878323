import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";

import { AllBaseCategories, BaseCategory } from "../reducers/avatarCreator";
import {
  NullableOptionColorDesignator,
  OptionColorDesignator,
} from "../helpers/avatarAssets";

export const isCompleteAvatar = (
  baseOptionsAndColors: Record<
    BaseCategory,
    NullableOptionColorDesignator | null
  >
): baseOptionsAndColors is Record<
  BaseCategory,
  NullableOptionColorDesignator
> =>
  AllBaseCategories.every(
    (c: BaseCategory) => baseOptionsAndColors[c] !== null
  );

export const serializeToStringV0 = (
  baseOptionsAndColors: Record<BaseCategory, OptionColorDesignator | null>
) => {
  // if (!isCompleteAvatar(baseOptionsAndColors)) {
  //   throw Error("Cannot serialize an incomplete avatar");
  // }
  //
  const asArray = AllBaseCategories.map((c: BaseCategory) =>
    baseOptionsAndColors[c] === null
      ? [null, null]
      : [baseOptionsAndColors[c]?.option, baseOptionsAndColors[c]?.color]
  );
  const serialized = compressToEncodedURIComponent(JSON.stringify(asArray));
  return `v0-${serialized}`;
};

export const deserializeFromStringV0 = (
  s: string
): Record<BaseCategory, NullableOptionColorDesignator> => {
  if (!s.startsWith("v0-")) {
    throw Error("input string is malformed");
  }

  const choicesArray: Array<BaseCategory> = JSON.parse(
    decompressFromEncodedURIComponent(s.replace(/^v0-/, "")) || ""
  );

  const deserialized = Object.fromEntries(
    AllBaseCategories.map((c: BaseCategory, i: number) => [
      c,
      {
        part: c,
        option: choicesArray[i][0],
        color: choicesArray[i][1],
      },
    ])
  ) as Record<BaseCategory, NullableOptionColorDesignator>;

  if (!isCompleteAvatar(deserialized)) {
    throw Error("input string does not correspond to a complete avatar");
  }
  return deserialized;
};
