import "./CategoryPicker.css";
import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { BaseCategory, pickBaseCategory } from "../reducers/avatarCreator";

export const CategoryPicker: React.FC = () => {
  return (
    <div id="avatar-customizer-category-picker">
      <CategoryPickerControl
        src="/avatar/body/AC_Head Icon.png"
        alt="Head"
        category="Head"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Back Hair Icon.png"
        alt="Back Hair"
        category="BackHair"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Front Hair Icon.png"
        alt="Front Hair"
        category="FrontHair"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Eyes Icon.png"
        alt="Eyes"
        category="Eyes"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Eyebrows Icon.png"
        alt="Eyebrows"
        category="Eyebrows"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Nose Icon.png"
        alt="Nose"
        category="Nose"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Ears Icon.png"
        alt="Ears"
        category="Ears"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Mouth Icon.png"
        alt="Mouth"
        category="Mouth"
      />
      <CategoryPickerControl
        src="/avatar/body/AC_Facial Hair Icon.png"
        alt="Facial Hair"
        category="FacialHair"
      />
    </div>
  );
};

type CategoryPickerControlProps = {
  alt: string;
  category: BaseCategory;
  src: string;
};

const CategoryPickerControl: React.FC<CategoryPickerControlProps> = ({
  alt,
  category,
  src,
}) => {
  const dispatch = useAppDispatch();

  const currentBaseCategory = useAppSelector(
    (state) => state.avatarCreator.currentBaseCategory
  );

  return (
    <button
      title={alt}
      onClick={() => dispatch(pickBaseCategory(category))}
      is-selected={category == currentBaseCategory ? "true" : null}
    >
      <img src={src} alt={alt} aria-hidden />
    </button>
  );
};
